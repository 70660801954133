import request from '@/utils/request'

export function getTestingUsers() 
{
    return request({
        url: '/testingusers/gettestingusers',
        method: 'get'
    })
}

export function addTestingUser(userID) {
    return request({
        url: '/testingusers/addtestinguser',
        method: 'post',
        data: {
            userID: userID
        }
    })
}

export function deleteTestingUser(userID) {
    return request({
        url: '/testingusers/deletetestinguser',
        method: 'post',
        data: {
            userID: userID
        }
    })
}