<template>
    <div class="app-container">
        <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading"> 
            <div class="filter-container">
                <el-input 
                placeholder="Testing User ID" 
                v-model="newTestingUser" 
                style="width: 400px;"
                class="filter-item"
                ></el-input>        
                
                <el-button 
                type="primary"
                class="filter-item"
                @click="onAddNewTestingUser"
                >Add New</el-button>          
            </div>
            <el-table :data="testingUsersList" default-expand-all>
                <el-table-column label="User ID"  width="300px">
                    <template slot-scope="{row}">
                    <span>{{ row }}</span>
                    </template>            
                </el-table-column>
                <el-table-column label="Actions" width="100px">
                    <template slot-scope="{row}">
                    <template>                
                        <el-button
                        type="danger"
                        size="small"
                        icon="el-icon-delete"
                        @click="onDeleteUserID(row)"
                        >Delete</el-button>
                    </template>
                    </template>
                </el-table-column>
            </el-table>
    </el-tabs>
  </div>
</template>


<script>
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";

import {
    getTestingUsers,
    addTestingUser,
    deleteTestingUser,
} from "@/api/testing_users";

import {
  HelperAddTestingUser,
  HelperRemoveTestingUser,
  HelperGetTestingUserList
} from "@/api/gameservice";


export default {
    name: "TestingUsers",
    components: {},
    data() {
        return {
        testingUsersList: [],
        newTestingUser: "",
        activeNames: "1",
        loading: false,
        };
    },
    created() {
        getTestingUsers()
        .then(response => {
            this.testingUsersList = [];
            this.testingUsersList = response.user_id_list;
        })
        .catch(error => {
        });
    },
  
methods: {

    //---------------------------------------------------------
    onAddNewTestingUser(){
        if (isStringEmpty(this.newTestingUser)) {
            Message({
                message: "Testing UserID is empty",
                type: "error",
                duration: 5 * 1000
            });
            return;
        }

        this.loading = true;

        addTestingUser(this.newTestingUser)
        .then(response => {

            this.loading = false;
            this.testingUsersList = response;
        })
        .catch(error => {
            this.loading = false;
        });

        // Add testing user to leaderboard testing list
        HelperAddTestingUser(this.newTestingUser, "")
        .then(response => {

        })
        .catch(error => {
            Message({message: "Try Add Testing User Failed: " + error, type: "error", duration: 5 * 1000});
        });


        this.newTestingUser = "";
    },

    //---------------------------------------------------------
    onDeleteUserID(row){

        let userID = row;

        if (isStringEmpty(userID)) {
            Message({
                message: "Can't delete UserID is empty",
                type: "error",
                duration: 5 * 1000
            });
            return;
        }

        this.loading = true;

        deleteTestingUser(userID)
        .then(response => {

            this.loading = false;
            this.testingUsersList = response;
        })
        .catch(error => {
            this.loading = false;
        });
      
        // Remove testing user from leaderboard testing list
        HelperRemoveTestingUser(userID)
        .then(response => {

        })
        .catch(error => {
            Message({message: "Try Remove Testing User Failed: " + error, type: "error", duration: 5 * 1000});
        });
    },
    
    //---------------------------------------------------------
    handleClick() {}
}
};
</script>